import styled from 'styled-components';
import {
    compose,
    flexDirection,
    FlexDirectionProps,
    flexWrap,
    FlexWrapProps,
    alignContent,
    AlignContentProps,
    justifyContent,
    JustifyContentProps,
    alignItems,
    AlignItemsProps,
} from 'styled-system';

export type FlexProps = FlexDirectionProps & FlexWrapProps & AlignContentProps & JustifyContentProps & AlignItemsProps;

export const Flex = styled.div<FlexProps>`
    display: flex;
    ${compose(flexDirection, flexWrap, alignContent, justifyContent, alignItems)}
`;

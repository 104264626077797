import type {FunctionComponent} from 'react';
import {useEffect} from 'react';
import {connect, MapStateToProps} from 'react-redux';

import type {AppState, NoneOwnProps} from 'core/types';
import notification from 'core/notification';
import router from 'core/router';
import init from 'core/init';
import type {SiteEnum} from 'core/enum';
import config from 'core/config';

import {Menu, Page, UserInfo} from './containers';

type ContainerProps = Readonly<{
    initialized: boolean;
    site: SiteEnum;
}>;

const Container: FunctionComponent<ContainerProps> = ({initialized, site}) => {
    useEffect(() => {
        document.documentElement.dataset.theme = site;
    }, []);

    return initialized ? (
        <Page menu={<Menu />} userInfo={<UserInfo />}>
            <router.Routes />
            <notification.Container />
        </Page>
    ) : null;
};

type StateProps = Pick<ContainerProps, 'initialized' | 'site'>;

const mapStateToProps: MapStateToProps<StateProps, NoneOwnProps, AppState> = (state) => ({
    initialized: init.isInitialized(state),
    site: config.getSite(state),
});

export default connect(mapStateToProps)(Container);

import styled, {css} from 'styled-components';

export const HyperLink = styled.a<{plain?: boolean; disabled?: boolean}>`
    color: inherit;
    text-decoration: ${({plain = false}) => (plain ? 'none' : 'underline')};
    text-decoration-color: currentColor;
    text-underline-offset: 0.1em;
    transition: color 250ms, text-decoration-color 250ms;
    ${({plain = false, theme, disabled = false}) =>
        disabled
            ? css`
                  &[disabled] {
                      cursor: default;
                  }
              `
            : css`
                  &:focus,
                  &:hover {
                      color: ${theme.color.secondaryHover};
                      text-decoration: ${plain ? 'none' : 'underline'};
                  }

                  &:focus {
                      color: ${theme.color.secondaryFocus};
                  }

                  &:hover {
                      color: ${theme.color.secondaryHover};
                      text-decoration-color: transparent;
                  }
              `};
`;

export const REGISTER_BOX = 'REGISTER_BOX';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const ACTIVATE = 'ACTIVATE';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const DASHBOARD = 'DASHBOARD';
export const BOX_DETAIL = 'BOX_DETAIL';
export const USER_PROFILE = 'USER_PROFILE';
export const ERROR = 'ERROR';
export const ADMIN_BOXES = 'ADMIN_BOXES';
export const ADMIN_USERS = 'ADMIN_USERS';

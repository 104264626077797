import {FC, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {Select} from 'components/select';
import CzechFlag from 'assets/flags/cz.svg';
import GermanFlag from 'assets/flags/de.svg';
import BritishFlag from 'assets/flags/gb.svg';
import i18n, {getDefaultLanguageBySite} from 'core/i18n';
import {media} from 'core/theme';
import {CookieItem, storage} from 'core/util';
import {Language} from 'core/types';
import user from 'core/user';
import type {SiteEnum} from 'core/enum';

type LanguageSelectionProps = Readonly<{site: SiteEnum}>;

export const LanguageSelection: FC<LanguageSelectionProps> = ({site}) => {
    const languageFromCookie = storage.get(CookieItem.Language) || getDefaultLanguageBySite(site);
    const [selectedLanguage, setSelectedLanguage] = useState(languageFromCookie);
    const dispatch = useDispatch();
    const loggedUser = useSelector(user.getUser);

    useEffect(() => {
        setSelectedLanguage(languageFromCookie);
    }, [languageFromCookie]);

    useEffect(() => {
        if (loggedUser) {
            setSelectedLanguage(loggedUser.language);
        }
    }, [loggedUser]);

    return (
        <SelectionWrapper>
            <Select
                value={selectedLanguage}
                noBackground
                options={[
                    {
                        key: Language.cs,
                        value: Language.cs,
                        label: <Flag as={CzechFlag} />,
                    },
                    {
                        key: Language.de,
                        value: Language.de,
                        label: <Flag as={GermanFlag} />,
                    },
                    {
                        key: Language.en,
                        value: Language.en,
                        label: <Flag as={BritishFlag} />,
                    },
                ]}
                onSelect={(value) => {
                    storage.set(CookieItem.Language, value);
                    i18n.changeLanguage(value);
                    setSelectedLanguage(value as Language);
                    dispatch(user.userActionGroup.updateUserLanguage(value as Language));
                }}
            />
        </SelectionWrapper>
    );
};

const Flag = styled.svg`
    width: 1.5rem;
    box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 0.2);
`;

const SelectionWrapper = styled.div`
    margin-left: 0.75rem;

    ${media.S(css`
        margin-left: 0.25rem;
    `)}
`;

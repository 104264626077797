import type {FunctionComponent} from 'react';
import {connect, MapStateToProps} from 'react-redux';

import type {AppState, NoneOwnProps} from 'core/types';
import router from 'core/router';
import i18n, {TFunction} from 'core/i18n';
import {Menu as MenuComponent} from 'components';
import user from 'core/user';

import {ADMIN_BOXES, ADMIN_USERS} from '../constants';

type ContainerProps = Readonly<{
    currentRoute: string | null;
    isLoggedUserAdmin: boolean;
}>;

const getAdminUserMenuItems = (currentRoute: string | null, t: TFunction) => [
    {
        key: ADMIN_BOXES,
        value: (
            <router.Link plain route={ADMIN_BOXES}>
                {t('admin.boxes')}
            </router.Link>
        ),
        disabled: currentRoute === ADMIN_BOXES,
    },
    {
        key: ADMIN_USERS,
        value: (
            <router.Link plain route={ADMIN_USERS}>
                {t('admin.users')}
            </router.Link>
        ),
        disabled: currentRoute === ADMIN_USERS,
    },
];

const MenuContainer: FunctionComponent<ContainerProps> = ({currentRoute, isLoggedUserAdmin}) => {
    const {t} = i18n.useTranslation();
    return isLoggedUserAdmin ? <MenuComponent items={getAdminUserMenuItems(currentRoute, t)} /> : null;
};

type StateProps = Pick<ContainerProps, 'currentRoute' | 'isLoggedUserAdmin'>;

const mapStateToProps: MapStateToProps<StateProps, NoneOwnProps, AppState> = (state) => ({
    currentRoute: router.getCurrentRoute(state),
    isLoggedUserAdmin: user.getIsLoggedUserAdmin(state),
});

export const Menu = connect(mapStateToProps)(MenuContainer);

import type {FormEvent, FunctionComponent} from 'react';
import {Modal as AntModal} from 'antd';
import styled, {css} from 'styled-components';

import {media} from 'core/theme';

import {Button, ButtonHTMLType, ButtonType} from '../button';

type ModalProps = Readonly<{
    visible: boolean;
    title: string;
    okLabel: string;
    cancelLabel?: string;
    fullWidthContent?: boolean;
    submitting: boolean;
    onSubmit: () => void;
    onCancel: () => void;
}>;

const MODAL_WIDTH = 572;

export const Modal: FunctionComponent<ModalProps> = ({
    visible,
    title,
    children,
    okLabel,
    cancelLabel,
    fullWidthContent = false,
    submitting,
    onSubmit,
    onCancel,
}) => {
    const handleSubmit = (event?: FormEvent) => {
        // Prevents reload on Enter
        event?.preventDefault();
        onSubmit();
    };

    return (
        <ModalWrapper>
            <AntModal
                visible={visible}
                title={title}
                maskClosable={false}
                footer={
                    <>
                        {cancelLabel && (
                            <Button
                                label={cancelLabel}
                                disabled={submitting}
                                onClick={onCancel}
                                type={ButtonType.Secondary}
                                htmlType={ButtonHTMLType.Button}
                            />
                        )}
                        <Button label={okLabel} disabled={submitting} onClick={handleSubmit} />
                    </>
                }
                width={MODAL_WIDTH}
                destroyOnClose
                onCancel={onCancel}
                getContainer={false}
            >
                <Content fullWidth={fullWidthContent}>{children}</Content>
            </AntModal>
        </ModalWrapper>
    );
};

const Content = styled.div<{fullWidth: boolean}>`
    max-width: ${({fullWidth}) => (fullWidth ? '100%' : '27rem')};
`;

const ModalWrapper = styled.form`
    ${media.S(css`
        & .ant-modal {
            top: 0;
            max-width: calc(100% - 2rem);
        }
    `)}
    ${media.M(css`
        & .ant-modal {
            max-width: calc(100% - 1rem);
        }
    `)}
`;

import type {FunctionComponent, ReactNode} from 'react';
import {Select as AntdSelect} from 'antd';
import styled, {css} from 'styled-components';

type Option = Readonly<{
    key: string;
    value: string;
    label?: ReactNode;
}>;

export type SelectProps = Readonly<{
    value: string;
    options: ReadonlyArray<Option>;
    activeItem?: string;
    noBackground?: boolean;
    onSelect: (value: string) => void;
}>;

export const Select: FunctionComponent<SelectProps> = ({options, value: selectedValue, noBackground = false, onSelect}) => {
    return (
        <StyledSelect
            defaultValue={selectedValue}
            value={selectedValue}
            noBackground={noBackground}
            showArrow={false}
            onSelect={(selected) => {
                onSelect(selected as string);
            }}
        >
            {options.map(({key, value, label}) => (
                <StyledSelect.Option value={value} key={key}>
                    {label || value}
                </StyledSelect.Option>
            ))}
        </StyledSelect>
    );
};

// TODO: styled only for no background use in header language selection
const StyledSelect = styled(AntdSelect)<Required<Pick<SelectProps, 'noBackground'>>>`
    ${({noBackground}) =>
        noBackground
            ? css`
                  background-color: transparent;

                  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
                      background-color: inherit;
                      border: none;
                      padding: 0;

                      & .ant-select-selection-item {
                          display: flex;
                          align-items: center;
                          padding: 0 0.5rem;
                      }
                  }
              `
            : css``}
`;

import type {FunctionComponent} from 'react';

import i18n from 'core/i18n';
import {Modal} from 'components';

type ConfirmModalProps = {
    visible: boolean;
    title: string;
    okLabel: string;
    submitting?: boolean;
    onSubmit: () => void;
    onCancel: () => void;
};

export const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
    children,
    visible,
    title,
    submitting = false,
    okLabel,
    onSubmit,
    onCancel,
}) => {
    const {t} = i18n.useTranslation();

    return (
        <Modal
            visible={visible}
            title={title}
            okLabel={okLabel}
            submitting={submitting}
            onSubmit={onSubmit}
            onCancel={onCancel}
            fullWidthContent
            cancelLabel={t('common.cancel')}
        >
            {children}
        </Modal>
    );
};

import {AsyncPackage, Loader} from 'core';
import type {ApplicationModule} from 'core/types';

import {
    REGISTER_BOX,
    LOGIN,
    ERROR,
    REGISTER,
    ACTIVATE,
    DASHBOARD,
    BOX_DETAIL,
    ADMIN_BOXES,
    ADMIN_USERS,
    PASSWORD_RESET,
    USER_PROFILE,
} from './constants';

const createRoute = (name: string, path: string, loader: Loader<ApplicationModule>) => ({
    [name]: {lazyPackage: AsyncPackage(loader), path},
});

export default {
    ...createRoute(LOGIN, '/login', () => import(/* webpackChunkName: "login" */ './pages/login')),
    ...createRoute(REGISTER, '/register', () => import(/* webpackChunkName: "register" */ './pages/register')),
    ...createRoute(ACTIVATE, '/activate', () => import(/* webpackChunkName: "activate" */ './pages/activate')),
    ...createRoute(PASSWORD_RESET, '/password-reset', () => import(/* webpackChunkName: "password-reset" */ './pages/passwordReset')),

    ...createRoute(BOX_DETAIL, '/box/:id', () => import(/* webpackChunkName: "box" */ './pages/boxDetail')),
    ...createRoute(DASHBOARD, '/', () => import(/* webpackChunkName: "box" */ './pages/boxDetail')),
    ...createRoute(USER_PROFILE, '/user-profile', () => import(/* webpackChunkName: "user-profile" */ './pages/userProfile')),
    ...createRoute(REGISTER_BOX, '/register-box', () => import(/* webpackChunkName: "register-box" */ './pages/registerBox')),
    ...createRoute(ADMIN_BOXES, '/admin/boxes', () => import(/* webpackChunkName: "admin-boxes" */ './pages/admin/boxes')),
    ...createRoute(ADMIN_USERS, '/admin/users', () => import(/* webpackChunkName: "admin-users" */ './pages/admin/users')),

    // TODO: we need to either fix this or delete it
    ...createRoute(ERROR, '/error', () => import(/* webpackChunkName: "error" */ './pages/error')),
};

import type {FunctionComponent} from 'react';
import styled, {css} from 'styled-components';

import {media} from 'core/theme';

import {Icon, IconEnum} from '../icon';

type UserInfoProps = {
    name: string;
    surname: string;
    disabled: boolean;
};

export const UserInfo: FunctionComponent<UserInfoProps> = ({name, surname, disabled}) => (
    <UserInfoContainer disabled={disabled}>
        <IconWrapper>
            <Icon iconName={IconEnum.userOutlined} />
        </IconWrapper>
        <Name>
            {name} {surname}
        </Name>
    </UserInfoContainer>
);

const UserInfoContainer = styled.div<{disabled: boolean}>`
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    margin-right: 0.75rem;
    color: ${({theme}) => theme.color.secondary};
    transition: color ${({theme}) => theme.transition.fast};

    ${({theme, disabled}) =>
        disabled
            ? ''
            : css`
                  &:hover {
                      color: ${theme.color.secondaryHover};
                  }

                  &:focus {
                      color: ${theme.color.secondaryFocus};
                  }
              `}
`;

const IconWrapper = styled.span`
    display: inline-flex;
    width: 1.75rem;
    height: 1.75rem;
    background-color: ${({theme}) => theme.color.white};
    color: ${({theme}) => theme.color.grayX0};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
`;

const Name = styled.span`
    max-width: 25ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${media.S(css`
        display: none;
    `)}
`;

import type {FunctionComponent} from 'react';
import {
    CaretDownFilled,
    CaretUpFilled,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    ExclamationCircleFilled,
    ExportOutlined,
    ImportOutlined,
    PictureOutlined,
    PlusOutlined,
    PoweroffOutlined,
    SyncOutlined,
    UserOutlined,
    WarningFilled,
} from '@ant-design/icons';

export enum IconEnum {
    caretDownFilled = 'caretDownFilled',
    caretUpFilled = 'caretUpFilled',
    checkOutlined = 'checkOutlined',
    closeOutlined = 'closeOutlined',
    deleteOutlined = 'deleteOutlined',
    editFilled = 'editFilled',
    exclamationCircleFilled = 'exclamationCircleFilled',
    exportOutlined = 'exportOutlined',
    importOutlined = 'importOutlined',
    pictureOutlined = 'pictureOutlined',
    plusOutlined = 'plusOutlined',
    poweroffOutlined = 'poweroffOutlined',
    syncOutlined = 'SyncOutlined',
    userOutlined = 'userOutlined',
    warningFilled = 'warningFilled',
}

const Icons: Readonly<Record<IconEnum, FunctionComponent<{spin?: boolean}>>> = {
    [IconEnum.caretDownFilled]: CaretDownFilled,
    [IconEnum.caretUpFilled]: CaretUpFilled,
    [IconEnum.checkOutlined]: CheckOutlined,
    [IconEnum.closeOutlined]: CloseOutlined,
    [IconEnum.deleteOutlined]: DeleteOutlined,
    [IconEnum.editFilled]: EditFilled,
    [IconEnum.exclamationCircleFilled]: ExclamationCircleFilled,
    [IconEnum.exportOutlined]: ExportOutlined,
    [IconEnum.importOutlined]: ImportOutlined,
    [IconEnum.pictureOutlined]: PictureOutlined,
    [IconEnum.plusOutlined]: PlusOutlined,
    [IconEnum.poweroffOutlined]: PoweroffOutlined,
    [IconEnum.syncOutlined]: SyncOutlined,
    [IconEnum.userOutlined]: UserOutlined,
    [IconEnum.warningFilled]: WarningFilled,
};

export type IconProps = Readonly<{
    iconName: IconEnum;
    spin?: boolean;
}>;

export const Icon: FunctionComponent<IconProps> = ({iconName, spin = false}) => {
    const Component = Icons[iconName];
    return <Component spin={spin} />;
};

import type {FunctionComponent, ReactNode} from 'react';
import {Menu as AntdMenu} from 'antd';
import styled from 'styled-components';

type MenuItem = Readonly<{
    key: string;
    value: ReactNode;
    disabled?: boolean;
}>;

export type MenuProps = Readonly<{
    items: ReadonlyArray<MenuItem>;
    activeItem?: string;
}>;

export const Menu: FunctionComponent<MenuProps> = ({items, activeItem}) => (
    <StyledMenu mode="horizontal" selectedKeys={activeItem ? [activeItem] : undefined}>
        {items.map(({key, value, disabled = false}) => (
            <StyledMenu.Item key={key} disabled={disabled}>
                {value}
            </StyledMenu.Item>
        ))}
    </StyledMenu>
);

const StyledMenu = styled(AntdMenu)`
    background-color: ${({theme}) => theme.color.primary};
    color: ${({theme}) => theme.color.secondary};
    border-bottom: none;

    & > .ant-menu-item:not(.ant-menu-dark) {
        top: 0;
        margin-top: 0;
    }

    & .ant-menu-item-disabled,
    & .ant-menu-submenu-disabled {
        cursor: default;
        user-select: none;
    }

    & .ant-menu-item-disabled a,
    & .ant-menu-submenu-disabled a {
        color: ${({theme}) => theme.color.gray60} !important;
    }
`;

import type {FunctionComponent, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {media} from 'core/theme';

import LogoSvg from './logo.svg';

export type PageProps = Readonly<{
    menu?: ReactNode;
    userInfo?: ReactNode;
    logoTitle?: string;
    link?: FunctionComponent;
}>;

const Logo: FunctionComponent<{logoTitle?: string}> = ({logoTitle}) => (
    <LogoLayout>
        <LogoImg />
        <LogoTitle>{logoTitle}</LogoTitle>
    </LogoLayout>
);

const Page: FunctionComponent<PageProps> = ({children, menu, userInfo, logoTitle = '', link: Link}) => {
    const logo = <Logo logoTitle={logoTitle} />;

    return (
        <Viewport>
            <Header>
                <LogoWrapper>{Link ? <Link>{logo}</Link> : logo}</LogoWrapper>
                <Menu>{menu}</Menu>
                <User>{userInfo}</User>
            </Header>
            <MaxWidthContent>{children || <div />}</MaxWidthContent>
            <Footer />
        </Viewport>
    );
};

const Viewport = styled.div`
    display: grid;
    justify-content: center;
    grid-template-rows: var(--header-height) 1fr auto;
    grid-template-columns: 1fr minmax(0, auto) 1fr;
    width: 100%;
    min-height: 100vh;
    row-gap: 1.25rem;

    ${media.S(css`
        row-gap: 1rem;
    `)}
`;

const MaxWidthContent = styled.div`
    grid-column: 2;
`;

const Header = styled.header`
    grid-column: 1 / -1;
    display: flex;
    align-items: flex-end;
    background-color: ${({theme}) => theme.color.primary};
    padding-inline: 2rem;

    ${media.S(css`
        padding-inline: 1rem;
    `)}
`;

const LogoWrapper = styled.span`
    align-self: center;
    line-height: 1;
    margin-bottom: -0.25rem;
`;

const LogoLayout = styled.span`
    display: inline-flex;
    align-items: flex-end;
    color: ${({theme}) => theme.color.secondary};
    transition: opacity 150ms ease;

    &:hover {
        color: ${({theme}) => theme.color.secondary};
        opacity: 0.85;
    }
`;

const LogoImg = styled(LogoSvg)`
    width: 3rem;
    margin-right: 0.5rem;
    fill: currentColor;

    ${media.S(css`
        width: 2.25rem;
    `)}
`;

const LogoTitle = styled.span`
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 1;
    white-space: nowrap;
    text-transform: uppercase;

    ${media.S(css`
        font-size: 1.25rem;
    `)}

    ${media.XXS(css`
        display: none;
    `)}
`;

const Menu = styled.div`
    flex: 1;
    margin-left: 1rem;
`;

const User = styled.div`
    align-self: center;
`;

const Footer = styled.footer`
    min-height: 2.5rem;
    background-color: ${({theme}) => theme.color.gray10};
    border-top: ${({theme}) => `${theme.color.gray20} solid 1px`};
    grid-column: 1 / -1;
`;

export default Page;

import type {FunctionComponent} from 'react';

import {Flex} from '../layouts/flex';

export type ErrorScreenProps = Readonly<{
    error: string;
}>;

const ErrorScreen: FunctionComponent<ErrorScreenProps> = ({error}) => <Flex>{error}</Flex>;

export default ErrorScreen;

import type {FunctionComponent} from 'react';

import router from 'core/router';
import i18n from 'core/i18n';
import {Page as PageComponent, PageProps as PageComponentProps} from 'components';

import {DASHBOARD} from '../constants';

type PageProps = Omit<PageComponentProps, 'logoTitle' | 'linkTarget'>;

const LogoLink: FunctionComponent = ({children}) => (
    <router.Link plain route={DASHBOARD}>
        {children}
    </router.Link>
);

export const Page: FunctionComponent<PageProps> = (props) => {
    const {t} = i18n.useTranslation();

    return <PageComponent {...props} logoTitle={t('page.menu.logoTitle')} link={LogoLink} />;
};

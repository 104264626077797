import type {FunctionComponent} from 'react';
import {MapStateToProps, connect} from 'react-redux';

import type {NoneOwnProps, AppState, UserDto} from 'core/types';
import user from 'core/user';
import router from 'core/router';
import config from 'core/config';
import {UserInfo as UserInfoComponent, Grid} from 'components';
import type {SiteEnum} from 'core/enum';

import {USER_PROFILE} from '../constants';
import {Logout} from './Logout';
import {LanguageSelection} from './LanguageSelection';

type UserInfoContainerProps = {
    loggedUser: UserDto | null;
    currentRoute: string | null;
    site: SiteEnum;
};

const UserInfoContainer: FunctionComponent<UserInfoContainerProps> = ({loggedUser, currentRoute, site}) => {
    const disabled = currentRoute === USER_PROFILE;

    return (
        <Grid gridAutoFlow="column" alignItems="center">
            {loggedUser !== null && (
                <>
                    <router.Link route={USER_PROFILE} plain disabled={disabled}>
                        <UserInfoComponent name={loggedUser.name} surname={loggedUser.surname} disabled={disabled} />
                    </router.Link>
                    <Logout />
                </>
            )}
            <LanguageSelection site={site} />
        </Grid>
    );
};

type StateProps = Pick<UserInfoContainerProps, 'loggedUser' | 'currentRoute' | 'site'>;

const mapStateToProps: MapStateToProps<StateProps, NoneOwnProps, AppState> = (state) => ({
    loggedUser: user.getUser(state),
    currentRoute: router.getCurrentRoute(state),
    site: config.getSite(state),
});

export const UserInfo = connect(mapStateToProps)(UserInfoContainer);

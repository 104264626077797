import {actionChannel, call, fork, put, take} from 'redux-saga/effects';
import type {Location} from 'history';

import {configApi, pageContextApi, translationsApi, usersApi} from 'core/api';
import i18n, {getDefaultLanguageBySite} from 'core/i18n';
import init from 'core/init';
import router from 'core/router';
import type {SagaWithReturnType} from 'core/types';
import user from 'core/user';
import config from 'core/config';
import message from 'core/message';
import {UNAUTHORIZED_ROUTES} from 'core/auth/constants';
import {CookieItem, createIsEnum, storage} from 'core/util';
import {SiteEnum} from 'core/enum';

import {LOGIN} from './constants';

const saga: SagaWithReturnType = function* () {
    yield call(router.delayedProgressStart, 0);
    try {
        yield call(loadContext);
        yield call(loadConfig);
        yield call(loadUser);
        // channel for taking router.ROUTE_ENTERED must be created prior calling router.setPageByLocationDirectly
        // if we just call take router.ROUTE_ENTERED afterwards, its apparently too late, action was already dispatched.
        // normally it would not happened because javascript is single threaded, but there is some history.replace magic, which
        // can probably somehow interrupt js event loop.
        const channel = yield actionChannel(router.ROUTE_ENTERED);
        // router.setPageByLocationDirectly will appropriately initialize and enter initial route for app according to URL.
        yield fork(router.setPageByLocationDirectly, window.location as unknown as Location); // TODO check if location type can be converted in better way
        // after all the magic is done (action router.ROUTE_ENTERED is dispatched)
        yield take(channel);
        channel.close(); // just good manners, we wont need this channel anymore
        // Why here instead in router? TODO :: Tomik CR
        yield fork(router.startRouting);
        // we can show application component, which has already all data for page needed.
        yield put(init.initialize());

        const storedLanguage = storage.get(CookieItem.Language);

        if (storedLanguage !== undefined) {
            yield call(i18n.changeLanguage, storedLanguage);
        }
    } finally {
        yield call(router.cancelProgressTask);
    }
};
export default saga;

const loadContext: SagaWithReturnType = function* () {
    const {locale} = yield call(pageContextApi.getConfig);
    const messages = yield call(translationsApi.getTranslations, locale.languageCode.toLowerCase());
    yield call(i18n.addTranslations, locale.id, messages);
};

const loadUser: SagaWithReturnType = function* () {
    try {
        const loggedUser = yield call(usersApi.loggedUser);
        yield put(user.userActionGroup.setLoggedUser(loggedUser));

        if (loggedUser.language !== undefined) {
            yield call(storage.set, CookieItem.Language, loggedUser.language);
            i18n.changeLanguage(loggedUser.language);
        }

        const isLoggedUserAdmin = yield call(usersApi.isLoggedUserAdmin);
        yield put(user.userActionGroup.setIsLoggedUserAdmin(isLoggedUserAdmin));
    } catch (e) {
        const {pathname, href} = window.location;

        if (!UNAUTHORIZED_ROUTES.includes(pathname)) {
            yield put(router.setLoginRedirectPath(href));
            yield put(router.navigate(LOGIN));
        }
    }
};

const loadConfig: SagaWithReturnType = function* () {
    try {
        const apiConfiguration = yield call(configApi.getConfig);
        const isSiteEnum = createIsEnum(SiteEnum);

        if (
            apiConfiguration.firebaseApiKey &&
            apiConfiguration.recaptchaApiKey &&
            apiConfiguration.site &&
            isSiteEnum(apiConfiguration.site)
        ) {
            yield put(config.configActionGroup.setConfig(apiConfiguration));
            const language = yield call(storage.get, CookieItem.Language);
            yield call(i18n.changeLanguage, language || getDefaultLanguageBySite(apiConfiguration.site));
        } else {
            message.show({translateKey: 'common.server.error', type: message.MessageType.Error});
        }
    } catch (e) {
        message.show({translateKey: 'common.server.error', type: message.MessageType.Error});
    }
};

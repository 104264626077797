import type {FunctionComponent} from 'react';
import {Button as AntButton} from 'antd';
import styled from 'styled-components';

import {Icon, IconEnum} from '../icon';

export enum IconButtonType {
    Primary = 'primary',
    Secondary = 'default',
}

export type IconButtonProps = Readonly<{
    iconName: IconEnum;
    type?: IconButtonType;
    filled?: boolean;
    fullWidth?: boolean;
    onClick: () => void;
}>;

export const IconButton: FunctionComponent<IconButtonProps> = ({
    iconName,
    filled = false,
    type = IconButtonType.Secondary,
    fullWidth = false,
    onClick,
}) => {
    return (
        <IconWrapper filled={filled} type={type} htmlType="button" fullWidth={fullWidth} onClick={onClick}>
            <Icon iconName={iconName} />
        </IconWrapper>
    );
};

const IconWrapper = styled(AntButton).withConfig({
    shouldForwardProp: (prop) => !['filled', 'fullWidth'].includes(prop),
})<{filled: boolean; fullWidth: boolean}>`
    --color: ${({theme, type}) => (type === IconButtonType.Primary ? theme.color.secondary : theme.color.gray40)};
    --size: ${({theme}) => theme.fontSize._24};
    width: ${({fullWidth}) => (fullWidth ? '100%' : 'var(--size)')};
    height: var(--size);
    padding: 0;
    border-radius: ${({fullWidth}) => (fullWidth ? '0.125rem' : '50%')};
    display: inline-block;
    border: none;
    box-shadow: none;

    &,
    &:hover,
    &:focus {
        background-color: ${({filled}) => (filled ? 'var(--color)' : 'transparent')};
        color: ${({theme, filled}) => (filled ? theme.color.white : 'var(--color)')};
    }

    &:hover {
        --color: ${({theme}) => theme.color.secondaryHover};
    }

    &:focus {
        --color: ${({theme}) => theme.color.secondaryFocus};
    }
`;

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {render} from 'react-dom';
import {ThemeProvider} from 'styled-components';

import {getAppReducers, createAppSaga, createProvider, corePackages} from 'core';
import {theme, GlobalStyles} from 'core/theme';
import router from 'core/router';
import {ReCaptchaProvider} from 'app/containers';
import {saga, Container, routes} from 'app';

import './index.css';

const reducers = getAppReducers(corePackages);
const mainSaga = createAppSaga(corePackages, saga, undefined);
const Root = createProvider(reducers, mainSaga);
router.registerRoutes(routes);

const App = () => (
    <Root>
        <ReCaptchaProvider>
            <GlobalStyles defaultTheme={theme} />
            <ThemeProvider theme={theme}>
                <Container />
            </ThemeProvider>
        </ReCaptchaProvider>
    </Root>
);

render(<App />, document.getElementById('app'));

import type {FunctionComponent, ReactNode} from 'react';
import {Button as AntdButton} from 'antd';
import styled, {css} from 'styled-components';

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'default',
    Text = 'text',
}

export enum ButtonHTMLType {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset',
}

export enum ButtonSize {
    Small = 'small',
    Large = 'large',
}

export type ButtonProps = Readonly<{
    label: string;
    disabled?: boolean;
    type?: ButtonType;
    htmlType?: ButtonHTMLType;
    icon?: ReactNode;
    size?: ButtonSize;
    onClick: () => void;
}>;

export const Button: FunctionComponent<ButtonProps> = ({
    label,
    disabled = false,
    type = ButtonType.Primary,
    htmlType = ButtonHTMLType.Submit,
    size = ButtonSize.Small,
    icon,
    onClick,
}) => (
    <StyledButton disabled={disabled} type={type} size={size} icon={icon} htmlType={htmlType} onClick={onClick}>
        {label}
    </StyledButton>
);

const StyledButton = styled(AntdButton)`
    font-size: ${({size, theme}) => (size === ButtonSize.Small ? theme.fontSize._14 : theme.fontSize._16)};
    height: ${({size, theme}) => (size === ButtonSize.Small ? theme.dimension.inputHeight : theme.dimension.buttonLarge)};
    padding: 0 1rem;
    line-height: 1;
    text-shadow: none;
    display: inline-flex;
    align-items: center;

    & .anticon {
        line-height: 0;
    }

    ${({type, theme}) => {
        switch (type) {
            case ButtonType.Primary:
                return css`
                    background-color: ${theme.color.primary};
                    border-color: ${theme.color.primary};
                    color: ${theme.color.secondary};

                    &:focus {
                        background-color: ${theme.color.primaryHover};
                        color: ${theme.color.secondary};
                        border-color: ${theme.color.secondaryFocus};
                    }

                    &:hover {
                        background-color: ${theme.color.primaryHover};
                        color: ${theme.color.secondary};
                        border-color: ${theme.color.secondaryHover};
                    }
                `;
            case ButtonType.Text:
                return css`
                    color: ${theme.color.secondary};
                    padding: 0;

                    &,
                    &:focus,
                    &:hover {
                        background-color: transparent;
                    }

                    &:focus {
                        color: ${theme.color.secondaryFocus};
                    }

                    &:hover {
                        color: ${theme.color.secondaryHover};
                    }
                `;
            default:
                return css`
                    border-color: ${theme.color.gray30};

                    &:focus {
                        color: ${theme.color.secondary};
                        border-color: ${theme.color.secondaryFocus};
                    }

                    &:hover {
                        color: ${theme.color.secondary};
                        border-color: ${theme.color.secondaryHover};
                    }
                `;
        }
    }}
`;

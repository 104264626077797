import type {FunctionComponent} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {connect, MapStateToProps} from 'react-redux';

import type {NoneOwnProps, AppState} from 'core/types';
import config from 'core/config';

type ReCaptchaProviderComponentProps = Readonly<{
    reCaptchaKey: string;
}>;

const ReCaptchaProviderComponent: FunctionComponent<ReCaptchaProviderComponentProps> = ({children, reCaptchaKey}) =>
    reCaptchaKey ? <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{children}</GoogleReCaptchaProvider> : <>{children}</>;

type StateProps = Pick<ReCaptchaProviderComponentProps, 'reCaptchaKey'>;

const mapStateToProps: MapStateToProps<StateProps, NoneOwnProps, AppState> = (state) => ({
    reCaptchaKey: config.getRecaptchaApiKey(state),
});

export const ReCaptchaProvider = connect(mapStateToProps)(ReCaptchaProviderComponent);

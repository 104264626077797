import styled from 'styled-components';
import {
    compose,
    gridTemplateColumns,
    GridTemplateColumnsProps,
    gridTemplateRows,
    GridTemplateRowsProps,
    gridTemplateAreas,
    GridTemplateAreasProps,
    gridColumnGap,
    GridColumnGapProps,
    gridRowGap,
    GridRowGapProps,
    gridGap,
    GridGapProps,
    justifyItems,
    JustifyItemsProps,
    alignItems,
    AlignItemsProps,
    justifyContent,
    JustifyContentProps,
    alignContent,
    AlignContentProps,
    gridAutoColumns,
    GridAutoColumnsProps,
    gridAutoRows,
    GridAutoRowsProps,
    gridAutoFlow,
    GridAutoFlowProps,
    gridColumn,
    GridColumnProps,
    gridRow,
    GridRowProps,
    gridArea,
    GridAreaProps,
    justifySelf,
    JustifySelfProps,
    alignSelf,
    AlignSelfProps,
} from 'styled-system';

type GridComponentProps = Readonly<
    GridTemplateColumnsProps &
        GridTemplateRowsProps &
        GridTemplateAreasProps &
        GridColumnGapProps &
        GridRowGapProps &
        GridGapProps &
        JustifyItemsProps &
        AlignItemsProps &
        JustifyContentProps &
        AlignContentProps &
        GridAutoColumnsProps &
        GridAutoRowsProps &
        GridAutoFlowProps &
        GridColumnProps &
        GridRowProps &
        GridAreaProps &
        JustifySelfProps &
        AlignSelfProps
>;

export const Grid = styled.div<GridComponentProps>`
    display: grid;
    ${compose(
        gridTemplateColumns,
        gridTemplateRows,
        gridTemplateAreas,
        gridColumnGap,
        gridRowGap,
        gridGap,
        justifyItems,
        alignItems,
        justifyContent,
        alignContent,
        gridAutoColumns,
        gridAutoRows,
        gridAutoFlow,
        gridColumn,
        gridRow,
        gridArea,
        justifySelf,
        alignSelf,
    )}
`;

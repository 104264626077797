import {FunctionComponent, useState} from 'react';
import {connect, MapDispatchToProps} from 'react-redux';
import {isMobile} from 'react-device-detect';

import type {NoneOwnProps} from 'core/types';
import i18n from 'core/i18n';
import auth from 'core/auth';
import {IconButton, IconButtonType, IconEnum, Tooltip} from 'components';

import {ConfirmModal} from './ConfirmModal';

type LogoutButtonProps = Readonly<{
    onLogout: () => void;
}>;

const LogoutButton: FunctionComponent<LogoutButtonProps> = ({onLogout}) => {
    const {t} = i18n.useTranslation();
    const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

    return (
        <>
            <Tooltip title={t('page.menu.logout')} placement="bottomRight" disabled={isMobile}>
                <span>
                    <IconButton
                        iconName={IconEnum.poweroffOutlined}
                        filled
                        type={IconButtonType.Primary}
                        onClick={() => setIsLogoutModalVisible(true)}
                    />
                </span>
            </Tooltip>
            <ConfirmModal
                visible={isLogoutModalVisible}
                title={t('page.menu.logout')}
                okLabel={t('page.menu.logout')}
                onSubmit={onLogout}
                onCancel={() => setIsLogoutModalVisible(false)}
            >
                {t('page.menu.logout.confirm')}
            </ConfirmModal>
        </>
    );
};

type DispatchProps = Pick<LogoutButtonProps, 'onLogout'>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, NoneOwnProps> = (dispatch) => ({
    onLogout: () => dispatch(auth.sessionActionGroup.logout()),
});

export const Logout = connect(null, mapDispatchToProps)(LogoutButton);

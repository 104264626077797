import type {FunctionComponent} from 'react';
import {Tooltip as AntTooltip, TooltipProps as AntTooltipProps} from 'antd';

export type TooltipProps = AntTooltipProps &
    Readonly<{
        disabled?: boolean;
    }>;

export const Tooltip: FunctionComponent<TooltipProps> = ({children, disabled = false, ...props}) =>
    disabled ? (
        <>{children}</>
    ) : (
        <AntTooltip {...props} color="white" overlayInnerStyle={{color: 'inherit'}}>
            {children}
        </AntTooltip>
    );
